<template>
  <div v-if="isLoading" class="card">
    <div class="card-body">
      <el-skeleton :rows="8" animated />
    </div>
  </div>
  <gradient-line-chart
    v-if="chartLine"
    id="chart-line"
    title="Sales Overview"
    description="<i class='fa fa-arrow-up text-success'></i><span class='font-weight-bold'>4% more</span> in 2021"
    :chart="{
      labels: chartData.labels,
      datasets: [
        {
          label: chartData.datasets[0].label,
          data: chartData.datasets[0].data,
        },
      ],
    }"
  />
</template>

<script>
import axios from "axios";
import GradientLineChart from "../../examples/Charts/GradientLineChart.vue";
import { ElSkeleton } from "element-plus";
export default {
  name: "ChartSales",
  components: {
    GradientLineChart,
    ElSkeleton,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      urlread: "/api/v1/report/product_sales",
      urlreadfromadmin: "/api/v1/report/product_sales/admin",
      datas: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [50, 40, 300, 220, 500, 250, 400, 230, 100],
          },
        ],
      },
      isLoading: true,
      chartLine: false,
    };
  },
  created() {
    this.ProductSales();
  },
  methods: {
    async ProductSales() {
      try {
        this.isLoading = true;
        this.chartLine = false;
        let url = this.is_admin == true ? this.urlreadfromadmin : this.urlread;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data.data.data;
        this.chartData.labels = this.datas.labels;
        this.chartData.datasets[0].label = this.datas.datasets[0].label;
        this.chartData.datasets[0].data = this.datas.datasets[0].data;

        this.isLoading = false;
        this.chartLine = true;

        //console.log(response.data);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.chartLine = true;
      }
    },
  },
};
</script>

<style></style>
