<template>
  <mini-statistics-card
    title="Saldo Terkini"
    :value="displayGrandTotalBalance"
    description="<span
                class='text-sm font-weight-bolder text-success'
                >+0%</span> bulan lalu"
    :icon="{
      component: 'ni ni-money-coins',
      background: 'bg-gradient-primary',
      shape: 'rounded-circle',
    }"
  />
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
export default {
  name: "CardTotalBalance",
  components: {
    MiniStatisticsCard,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      urlread: "/api/v1/report/balance",
      urlreadfromadmin: "/api/v1/report/balance/admin",
      totalBalance: 0,
      grandTotalProfitFromCOD: 0,
      displayGrandTotalBalance: "loading...",
    };
  },
  created() {
    this.TotalBalance();
  },
  methods: {
    async TotalBalance() {
      try {
        let url = this.is_admin == true ? this.urlreadfromadmin : this.urlread;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //this.datas = response.data.data.data;
        this.grandTotalProfitFromCOD = response.data.data.grandtotal_profit;
        this.displayGrandTotalBalance = response.data.data.display_grandtotal_balance;

        //console.log(response.data);
      } catch (error) {
        console.log(error);
        this.displayGrandTotalProfitFromCOD = "error";
      }
    },
  },
};
</script>

<style></style>
