<template>
  <div class="mt-4 col-sm-4 mt-sm-0">
    <div :class="classBoxLink">
      <div class="p-3 card-body position-relative">
        <div v-if="isLoading">
          <el-skeleton
            :rows="2"
            animated
          />
        </div>
        <div v-else>
          <div class="row">
            <div class="col-7 text-start">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">
                {{ title }}
              </p>
              <h5 class="mb-0 font-weight-bolder">{{ count }}</h5>
              <span
                :class="`text-${percentage.color}`"
                class="mt-auto mb-0 text-sm text-end font-weight-bolder"
                ><span class="hover-text-white">{{ percentage.value }}</span>
                <span class="font-weight-normal text-secondary">&nbsp;<span class="hover-text-white">{{ percentage.label }}</span></span
                >
              </span>
            </div>
            <div class="col-5">
              <div class="dropdown text-end">
                <a
                  id="dropdownUsers1"
                  href="javascript:;"
                  class="cursor-pointer text-secondary"
                  :class="{ show: showMenu }"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  @click="showMenu = !showMenu"
                >
                  <span class="text-xs text-secondary"><span class="hover-text-white">{{ menu }}</span></span>
                </a>
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  :class="{ show: showMenu }"
                  aria-labelledby="dropdownUsers1"
                >
                  <li v-for="(drop, index) in dropdown" :key="index">
                    <a
                      class="dropdown-item border-radius-md"
                      :href="drop.route"
                      >{{ drop.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElSkeleton } from "element-plus";
export default {
  name: "StatisticsCard",
  components: {
    ElSkeleton,
  },
  props: {
    title: {
      type: String,
      default: "Default Statistics Card",
    },
    count: {
      type: String,
      default: "",
    },
    menu: {
      type: String,
      default: "",
    },
    percentage: {
      type: Object,
      default: () => {},
      color: String,
      value: String,
      label: String,
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    is_active: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMenu: false,
      classBoxLink: "card box-link",
    }
  },
  created() {
    this.checkActiveBoxLink();
  },
  methods: {
    checkActiveBoxLink() {
      if(this.clickable == true) {
        if(this.is_active == true) {
          this.classBoxLink = "card box-link box-link-active"
        }else{
          this.classBoxLink = "card box-link"
        }
      }else{
        this.classBoxLink = "card";
      }
    }
  }
};
</script>

<style scoped>
  .box-link {
    cursor:pointer;
  }
  .box-link-active {
    background: #6c6be4;
  }
  .box-link-active p {
    color: #ffffff;
  }
  .box-link-active h5 {
    color: #ffffff;
  }
  .box-link-active .hover-text-white {
    color: #ffffff;
  }
  .box-link:hover {
    background: #6c6be4;
  }
  .box-link:hover p {
    color: #ffffff;

  }
  .box-link:hover h5 {
    color: #ffffff;
  }
  .box-link:hover .hover-text-white {
    color: #ffffff;
  }
</style>